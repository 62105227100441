<template>
	<div id="goodsCheck">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-select v-model:value="audit" @change="changeGoodsStatus">
					<a-select-option value="0">待审核</a-select-option>
					<a-select-option value="1">已通过</a-select-option>
					<a-select-option value="2">未通过</a-select-option>
				</a-select>
				<a-input-search style="width: 400px;"
					v-model:value="key" 
					placeholder="请输入查询关键字" 
					enter-button 
					@search="getGoodsList(1,info.limit)" />
				<a-button type="danger" @click="checkGoods(pks,1)">批量通过审核</a-button>
			</a-space>
		</div>
		
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-tabs v-model:activeKey="type" @change="getGoodsList(1,info.limit)">
			    <a-tab-pane key="goods" tab="商品">
					<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
						{title:'ID',dataIndex:'id'},
						{title:'商品',dataIndex:'goods_name',slots:{customRender:'goods_name'}},
						{title:'上架',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
						{title:'推荐',dataIndex:'is_recommend',slots:{customRender:'is_recommend'}},
						{title:'商品审核状态',dataIndex:'audit',slots:{customRender:'audit'}},
						{title:'排序',dataIndex:'rank'},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
						<template #goods_name="{record}">
							<div style="display: flex;align-items: center;">
								<a-image style="width: 40px; height: 40px;margin-right: 5px;" :src="record.cover" />
								<div style="width: 70%;">{{record.goods_name}}</div>
							</div>
						</template>
						<template #is_put_away="{record}">
							<a-tag :color="record.is_put_away == 1 ?'#00CC66':'#999'">
								{{record.is_put_away == 1?'已上架':'已下架'}}
							</a-tag>
						</template>
						<template #is_recommend="{record}">
							<a-tag :color="record.is_recommend == 1 ?'#00CC66':'#999'">
								{{record.is_recommend == 1?'推荐':'不推荐'}}
							</a-tag>
						</template>
						<template #audit="{record}">
							<a-tag color="#00CC66" v-if="record.audit==1">审核通过</a-tag>
							<a-tag color="#FF9900" v-if="record.audit==0">待审核</a-tag>
							<a-tag color="#FF0066" v-if="record.audit==2">审核未通过</a-tag>
						</template>
						<template #action="{record}">
							<a-space>
								<a-button v-has="{action:'/goods/goodsEdit'}" type="link" @click="toEditGoods(record.id,record.shop_id)" size="small">详情</a-button>
								<a-button v-has="{action:'wxapp_marketing_goods_check'}" v-if="audit==0"  type="link" @click="checkGoods([record.id],1)"  size="small">[通过]</a-button>
								<a-button v-has="{action:'wxapp_marketing_goods_check'}" v-if="audit==0" type="link" @click="checkGoods([record.id],2)"  size="small">[不通过]</a-button>
							</a-space>
						</template>
					</a-table>
				</a-tab-pane>
			    <a-tab-pane key="adopt" tab="认养">
					<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
						{title:'ID',dataIndex:'id'},
						{title:'认养信息',dataIndex:'goods_name',slots:{customRender:'goods_name'}},
						{title:'上架',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
						{title:'推荐',dataIndex:'is_recommend',slots:{customRender:'is_recommend'}},
						{title:'商品审核状态',dataIndex:'audit',slots:{customRender:'audit'}},
						{title:'排序',dataIndex:'rank'},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]"  :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
						<template #goods_name="{record}">
							<div style="display: flex;align-items: center;">
								<a-image style="width: 40px; height: 40px;margin-right: 5px;" :src="record.cover" />
								<span>{{record.name}}</span>
							</div>
						</template>
						<template #is_put_away="{record}">
							<a-tag :color="record.is_put_away == 1 ?'#00CC66':'#999'">
								{{record.is_put_away == 1?'已上架':'已下架'}}
							</a-tag>
						</template>
						<template #is_recommend="{record}">
							<a-tag :color="record.is_recommend == 1 ?'#00CC66':'#999'">
								{{record.is_recommend == 1?'推荐':'不推荐'}}
							</a-tag>
						</template>
						<template #audit="{record}">
							<a-tag color="#00CC66" v-if="record.audit==1">审核通过</a-tag>
							<a-tag color="#FF9900" v-if="record.audit==0">待审核</a-tag>
							<a-tag color="#FF0066" v-if="record.audit==2">审核未通过</a-tag>
						</template>
						<template #action="{record}">
							<a-space>
								<a-button v-has="{action:'/goods/goodsEdit'}" type="link" @click="toEditGoods(record.id,record.shop_id)" size="small">详情</a-button>
								<a-button v-has="{action:'wxapp_marketing_goods_check'}" v-if="audit==0"  type="link" @click="checkGoods([record.id],1)"  size="small">[通过]</a-button>
								<a-button v-has="{action:'wxapp_marketing_goods_check'}" v-if="audit==0" type="link" @click="checkGoods([record.id],2)"  size="small">[不通过]</a-button>
							</a-space>
						</template>
					</a-table>
				</a-tab-pane>
			    <a-tab-pane key="land" tab="土地">
					<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
						{title:'ID',dataIndex:'id'},
						{title:'土地信息',dataIndex:'goods_name',slots:{customRender:'goods_name'}},
						{title:'上架',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
						{title:'商品审核状态',dataIndex:'audit',slots:{customRender:'audit'}},
						{title:'排序',dataIndex:'rank'},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }" >
						<template #goods_name="{record}">
							<div style="display: flex;align-items: center;">
								<a-image style="width: 40px; height: 40px;margin-right: 5px;" :src="record.cover" />
								<span>{{record.land_name}}</span>
							</div>
						</template>
						<template #is_put_away="{record}">
							<a-tag :color="record.is_put_away == 1 ?'#00CC66':'#999'">
								{{record.is_put_away == 1?'已上架':'已下架'}}
							</a-tag>
						</template>
						<template #audit="{record}">
							<a-tag color="#00CC66" v-if="record.audit==1">审核通过</a-tag>
							<a-tag color="#FF9900" v-if="record.audit==0">待审核</a-tag>
							<a-tag color="#FF0066" v-if="record.audit==2">审核未通过</a-tag>
						</template>
						<template #action="{record}">
							<a-space>
								<a-button v-has="{action:'/goods/goodsEdit'}" type="link" @click="toEditGoods(record.id,record.shop_id)" size="small">详情</a-button>
								<a-button v-has="{action:'wxapp_marketing_goods_check'}" v-if="audit==0"  type="link" @click="checkGoods([record.id],1)"  size="small">[通过]</a-button>
								<a-button v-has="{action:'wxapp_marketing_goods_check'}" v-if="audit==0" type="link" @click="checkGoods([record.id],2)"  size="small">[不通过]</a-button>
							</a-space>
						</template>
					</a-table>
				</a-tab-pane>
				<a-tab-pane key="live" tab="监控">
					<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
						{title:'ID',dataIndex:'id'},
						{title:'监控标题',dataIndex:'title'},
						{title:'uid',dataIndex:'live_uid'},
						{title:'审核状态',dataIndex:'audit',slots:{customRender:'audit'}},
						{title:'排序',dataIndex:'rank'},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }" >
						<template #goods_name="{record}">
							<div style="display: flex;align-items: center;">
								<a-image style="width: 40px; height: 40px;margin-right: 5px;" :src="record.cover" />
								<span>{{record.land_name}}</span>
							</div>
						</template>
						<template #is_put_away="{record}">
							<a-tag :color="record.is_put_away == 1 ?'#00CC66':'#999'">
								{{record.is_put_away == 1?'已上架':'已下架'}}
							</a-tag>
						</template>
						<template #audit="{record}">
							<a-tag color="#00CC66" v-if="record.audit==1">审核通过</a-tag>
							<a-tag color="#FF9900" v-if="record.audit==0">待审核</a-tag>
							<a-tag color="#FF0066" v-if="record.audit==2">审核未通过</a-tag>
						</template>
						<template #action="{record}">
							<a-space>
								<a-button v-has="{action:'/goods/goodsEdit'}" type="link" @click="toEditGoods(record.id,record.shop_id)" size="small">详情</a-button>
								<a-button v-has="{action:'wxapp_marketing_goods_check'}" v-if="audit==0"  type="link" @click="checkGoods([record.id],1)"  size="small">[通过]</a-button>
								<a-button v-has="{action:'wxapp_marketing_goods_check'}" v-if="audit==0" type="link" @click="checkGoods([record.id],2)"  size="small">[不通过]</a-button>
							</a-space>
						</template>
					</a-table>
				</a-tab-pane>
			</a-tabs>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getGoodsList(info.page,e)}"
					@change="(e)=>{getGoodsList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import commonModel from '@/api/common';
import tool from '@/util/tool';
import { $post } from '@/api/http'
import router from '@/router'
export default{
	setup(){
		const state = reactive({
			type:'goods',  //类型 goods 商品  live 监控 adopt 认养  land土地
			key:'',
			info:{
				list:[],
				page:1,
				limit:10,
				count:0,
			},
			pks:[],
			audit:"0",
		})

		getGoodsList(1,state.info.limit)

		function getGoodsList(page,limit){
			let { key ,audit ,type } =state
			let data ={ page, limit ,audit}
			
			if( key !='' ) data.key=key
			let api = 'getGoodsAuditList'
			if( type == 'adopt') api ='getAdoptAuditList'
			if( type == 'land') api ='getLandAuditList'
			if( type == 'live') api ='getLiveAuditList'
			if( data.page == 1) state.info.list =[]
			commonModel.getDataList(api,data).then(res=>state.info = {limit,...res})
		}

		 
		// audit 1审核通过  2 不通过
		function checkGoods(ids,audit){
			let { type } = state ,api = 'changeAudit'
			if( type =='adopt') api ='setAdoptAudit'
			if( type =='land') api ='setLandAudit'
			if( type =='live') api ='setLiveAudit'
			tool.confirm("确认审核选中的物品信息吗?").then(()=>{
				if( type =='goods'){
					$post(api,{id:ids,audit}).then(res=>{
						tool.message("操作成功");
						getGoodsList(state.info.page,state.info.limit)
					})
				}else{
					$post(api,{id:ids,audit}).then(res=>{
						tool.message("操作成功");
						getGoodsList(state.info.page,state.info.limit)
					})
				}
			}).catch(()=>{})
		}

		function toEditGoods(id,shop_id=0){
			let { type } = state ,path = '/goods/goodsEdit'
			if( type == 'adopt') path ='/adopt/edit'
			if( type == 'land') path ='/land/landEdit'
			if( type == 'live') path ='/live/liveEdit'
			if( shop_id ){
				router.push({path:path,query:{id,shop_id} })	
			}else{
				router.push({path:path,query:{id} })
			}
			
		}

		function changeGoodsStatus(){
			getGoodsList(state.info.page,state.info.limit)
		}

		return{
			...toRefs(state),
			getGoodsList,
			checkGoods,
			toEditGoods,
			changeGoodsStatus
		}
	}
}
</script>

<style lang="scss">
	.goods-search-input{
		width: 350px;
	}
	.kd-goodsList{
		width: 100%;
		display: flex;
		
		&-cover{
			width: 100px;
			height: 100px;
			margin-right: 10px;
		}
		&-category{
			color: red;
		}
		
	}
</style>
